
import { defineComponent, ref, computed } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import UploadDataStep from '@/components/pages/contacts/import/flow/steps/UploadDataStep.vue'
import MatchFieldsStep from '@/components/pages/contacts/import/flow/steps/MatchFieldsStep.vue'
import CarrierLookupPreviewStep from '@/components/pages/services/carrierLookup/flow/CarrierLookupPreviewStep.vue'
import ImportResultsStep from '@/components/pages/contacts/import/flow/steps/ImportResultsStep.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'ServicesCarrierLookupNewFlow',
  components: { ImportResultsStep, CarrierLookupPreviewStep, MatchFieldsStep, UploadDataStep, FlowStepList, TmButton, DetailsHero, PageBlock },
  setup() {
    const { isSmMax } = useBreakpoints()

    const breadcrumbs: BreadcrumbsLink[] = [
      { label: 'Services', name: 'base.services.countries.index' },
      { label: 'Carrier lookup', name: 'base.services.carrierLookup.index' },
      { label: 'Bulk lookup' },
    ]
    const currentStep = ref(1)

    const file = ref()
    const currentMethod = ref('file')
    const isImportFinished = ref(false)
    const uploadMethods = computed(() => [
      {
        type: 'file',
        icon: 'tmi-attachment',
        title: 'Upload a file',
        description: 'Upload a file with the phone numbers that you want to validate',
      },
      {
        type: 'current-app',
        icon: 'tmi-duplicate',
        title: isSmMax.value ? 'Copy & paste' : 'Copy and paste phone numbers',
        description: 'Paste one number per line or separate with commas.',
      },
    ])

    return {
      isSmMax,
      breadcrumbs,
      currentStep,
      file,
      currentMethod,
      isImportFinished,
      uploadMethods,
    }
  },
})
