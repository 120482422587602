
import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import type { CardStats } from '@/definitions/shared/types'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: { TmTooltip },
  props: {
    items: {
      type: Array as PropType<CardStats[]>,
      required: true,
    },
    cardsLineCount: {
      type: Number,
      default: 3,
    },
    cardsSize: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {}
  },
})
