
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import CardsStats from '@/components/shared/templates/CardsStats.vue'
import type { CardStats } from '@/definitions/shared/types'

export default defineComponent({
  name: 'CarrierLookupPreviewStep',
  components: {
    FlowStep,
    CardsStats,
  },
  props: {
    step: {
      type: Number,
      default: 3,
    },
    title: {
      type: String,
      default: 'Preview',
    },
    editButton: {
      type: String,
      default: 'Edit',
    },
  },
  setup() {
    const card = ref<CardStats[]>([
      {
        title: 'Data rows',
        value: 6,
      },
      {
        title: 'Estimated time',
        value: '10 sec',
      },
      {
        title: 'Price per one row',
        value: '$0.04',
      },
      {
        title: 'Total cost',
        value: '$0.08',
      },
    ])

    return {
      card,
    }
  },
})
